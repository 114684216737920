<template>
  <div class="router-error-404-catchall" v-if="this.bot && bot.handle">
    <titleBar
      :title="title"
      :img="bot.avatar"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
    />

    <div class="section">
      <div class="container">
        <empty
          :title="title"
          :hrefTo="bot.discordAddToServerURL"
          btLabel="Add to server"
          img="https://cdn1.onlybots.cc/bots/OnlyBots.assets/posts/Jiku4-wAY/d9ko24iOPT____e99be2c4-1ec6-4ed6-8351-7b8c43397194.jpeg"
        />

        <br />
        <p>
          You can also check out OnlyBot official discord here:
          <a href="https://discord.gg/hKRfQa8Bzq"> discord.gg/hKRfQa8Bzq</a>
        </p>
        <!--
      <h1>This is an 404 page - not found...</h1>
  
  
          <h1>This is an 404 page - not found...</h1>
              <h1>This is an 404 page - not found...</h1>
                  <h1>This is an 404 page - not found...</h1>
                      <h1>This is an 404 page - not found...</h1>
  
                    -->
      </div>
    </div>
  </div>
</template>
<script>
import e404 from "@/components/e/e404";

export default {
  data() {
    return {
      transitionName: "fade", //default
      loadingBot: true,
      bot: {},
    };
  },
  components: {
    //   e404,
    //  Footer: Footer
  },
  mounted() {
    this.loadBot();
  },
  computed: {
    title() {
      return `Add ${this.bot.handle} to Discord`;
    },
  },
  methods: {
    loadBot() {
      this.loadingBot = true;
      var id = this.$route.params.bot;
      window.API.botProfile(id)
        .then((b) => {
          this.bot = b;
          this.loadingBot = false;
          //window.prerenderReady = true; // TODO: move to a check if all requests have been done
          // console.log("PROJJ!", proj, proj.id);
        })
        .catch((e) => {
          console.error("Error loading bot", e);
          this.loadingBot = false;
          this.bot = {};
        });

      //tabs data

      console.log("loadBot");
    },
    //   async getBotPost() {
    //     const { data } = await getBotPost(this.$route.params.id);
    //     this.post = data;
    //   },
  },
};
</script>
